var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:{
    'title-item-dark': _vm.$vuetify.theme.dark,
  },attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"mt-2",attrs:{"sm":"12","md":"3"}},[_c('v-label',[_vm._v(" Selecione uma empresa: ")])],1),_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","label":"Empresas  cadastradas","items":[_vm.empresas],"item-text":"pessoa.nome","item-value":"id","readonly":_vm.readonly,"loading":_vm.carregando,"append-icon":"","error-messages":_vm.errorMessages,"autocomplete":"false","hide-details":"auto"},model:{value:(_vm.empresaId),callback:function ($$v) {_vm.empresaId=$$v},expression:"empresaId"}})],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-data-table',{attrs:{"disable-filtering":"","disable-sort":"","server-items-length":_vm.totalRegistros,"headers":_vm.cabecalho,"items":_vm.areas,"options":_vm.paginacao,"footer-props":{
          'items-per-page-options': [10, 20, 50],
        }},on:{"update:options":function($event){_vm.paginacao=$event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('table-btn-editar',{attrs:{"temPermissao":_vm.$store.getters['usuario/temPermissao'](
                'editar.area_configuracao'
              ),"href":`/empresa/${_vm.empresaId}/area_configuracaoUser/${item.id}/editar`}})]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }